/* App.css */

.chat-container {
    display: flex;
    flex-direction: column;
    /* height: calc(var(--vh, 1vh) * 100); */
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
    /* margin-bottom: 50px; */
}

.messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* margin-bottom: 50px; */
}

.message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    max-width: 100%;
    position: relative;
}

.user-message {
    text-align: left;
    background-color: #000;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
}

.bot-message {
    /* align-self: flex-start; */
    position: relative;
    background-color: #f1f0f0;
}

.message-text {
    text-align: left;
    padding: 10px;
}

.input-container {
    display: flex;
    padding: 10px;
    background-color: #fff;
    /* Ensures input is always visible */
    border-top: 1px solid #ccc;
    /* Adds a separator line */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
}

input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

button {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #000;
    color: white;
    margin-left: 10px;
    cursor: pointer;
}

.copy-icon {
    display: flex;
    position: absolute;
    justify-self: right;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #666;
    transform: scale(1.2);
}

.copy-icon:hover {
    color: #000;
    transform: scale(1.3);
}

.typing-indicator {
    display: flex;
    align-items: center;
}

.typing-indicator span {
    width: 20px;
    height: 20px;
    margin: 0 2px;
    background-color: #000000;
    border-radius: 50%;
    display: inline-block;
    animation: typing 1.8s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
    animation-delay: -0.32s;
}

.typing-indicator span:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes typing {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}