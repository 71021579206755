.alert {
    padding: 20px;
    background-color: #f44336;
    /* Red */
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 98%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.closebtn {
    margin-left: 15px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    /* float: right; */
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.closebtn:hover {
    color: black;
}